export const COMMUNICATOR_API_KEY = "COMMUNICATOR_API_KEY";
export const COMMUNICATOR_CURRENT_USER_KEY = "COMMUNICATOR_CURRENT_USER_KEY";

export const networkActions = {
  AUTHENTICATE: "user/authenticate",
  SET_PASSWORD: "user/password",
  FORGOT_PASSWORD: "user/recovery",
  CREATE_PASSWORD: "user/setPassword",
  RESET_PASSWORD: "user/resetPassword",
  USER_LOG_OUT: "user/log-Out",
  USER_INFO: "user/info",
  SEND_EMAIL: "user/email",
  GET_BRANDING: "user/branding",
  USER_AVATAR: "user/avatar",
  USER_DATA: "user/data",
  REFRESH_TOKEN: "user/token/refresh",
  USER_EMAIL_CONFIRMED: "user/confirmed",
  TRANSACTIONS_DETAILS: "transaction/details",
  CLIENT_TRANSACTION_BY_ID: "transaction/client",
  CLIENT_TRANSACTIONS: "transaction/client/all",
  AGENT_TRANSACTIONS: "transaction/agent/all",
  AGENT_TRANSACTIONS_HISTORY: "transaction/agent/history",
  GET_CLIENT_TEAM_MEMBERS: "teamMember",
  DOCUMENT: "document",
  DOCUMENT_CREATE_ZIP: "Document/create/zip",
} as const;

export type NetworkActionsKeys = keyof typeof networkActions;
export type NetworkActionsValues = (typeof networkActions)[NetworkActionsKeys];
export type ToastErrorByUrlVisibilityConfig = {
  [key in NetworkActionsValues]?: {
    hide?: boolean;
  };
};
export const toastErrorByUrlVisibilityConfig: ToastErrorByUrlVisibilityConfig =
  {
    [networkActions.USER_EMAIL_CONFIRMED]: { hide: true },
  };
