import { combineReducers } from "redux";

import userReducer from "./userReducer";
import documentReducer from "./documentReducer";
import milestoneReducer from "./milestoneReducer";
import transactionReducer from "./transactionReducer";
import teamMemberReducer from "./teamMemberReducer";

const rootReducer = combineReducers({
  userReducer,
  documentReducer,
  milestoneReducer,
  transactionReducer,
  teamMemberReducer,
});

export default rootReducer;
