import React, { FC } from "react";
import { Portal } from "react-portal";

const defaultNode = document.body;

interface SpinnerProps {
  cover?: boolean;
  container?: HTMLElement | null;
  zIndex?: number;
}

const Spinner: FC<SpinnerProps> = ({ cover, container, zIndex }) => {
  const node = container || defaultNode;
  const inlineStyle = zIndex ? { style: { zIndex } } : {};

  return (
    <Portal node={node}>
      <div
        className={`spinner ${cover ? "cover" : ""} ${
          container ? "container" : ""
        }`}
        {...inlineStyle}
      >
        <span className="loader" />
      </div>
    </Portal>
  );
};

export default Spinner;
