export const milestonesTypeEnum = {
  PreliminaryTitleReport: {
    value: 0,
    name: "Preliminary Title Report",
  },
  HOADocuments: {
    value: 1,
    name: "HOA Documents",
  },
  NHDReport: {
    value: 2,
    name: "NHD Report",
  },
  CityReport: {
    value: 3,
    name: "City Documents",
  },
  InspectionContingency: {
    value: 4,
    name: "Inspection contingency",
  },
  FIRPTAWithHolding: {
    value: 5,
    name: "FIRPTA WITHHOLDING",
  },
  TermiteReport: {
    value: 6,
    name: "Termite Report",
  },
  InitialDeposit: {
    value: 7,
    name: "Initial Deposit",
  },
  EscrowOpeningPacket: {
    value: 8,
    name: "Escrow Opening Packet",
  },
  BuyerContingencyDue: {
    value: 9,
    name: "Client Contingency Due",
  },
  BuyerInspectionRemoved: {
    value: 10,
    name: "Client Inspection Removed",
  },
  LoanContingency: {
    value: 11,
    name: "Loan Contingency",
  },
  LoanDocuments: {
    value: 11,
    name: "Loan Documents",
  },
  LoanFunded: {
    value: 12,
    name: "Loan Funded",
  },
  DeedRecorder: {
    value: 13,
    name: "Deed Recorder",
  },
  Closed: {
    value: 14,
    name: "Closed",
  },
  BuyerContingenciesRemoved: {
    value: 14,
    name: "Client Contingencies Removed",
  },
} as const;

export enum UserTypeEnum {
  Buyer = "Buyer",
  Seller = "Seller",
  Agent = "Agent",
  ListingAgent = "ListingAgent",
  SellingAgent = "SellingAgent",
  ListingAssistant = "ListingAssistant",
  SellingAssistant = "SellingAssistant",
  ListingTransactionCoordinator = "ListingTransactionCoordinator",
  SellingTransactionCoordinator = "SellingTransactionCoordinator",
  TC = "Transaction Coordinator",
  TA = "Transaction Assistant",
}

export const transactionPhaseEnum = {
  completed: 0,
  pending: 1,
} as const;

export const OrderStatusEnum = {
  None: "None",
  OPEN: "Open",
  PENDING: "Pending",
  CLOSED: "Closed",
  CANCELLED: "Cancelled",
  ON_HOLD: "On_hold",
  PRE_OPEN: "Pre_open",
} as const;

export const EmailReceiverTypeEnum = {
  None: 0,
  AgentClient: 1,
  TeamMember: 2,
} as const;

export const CommunicateTypesEnum = {
  PHONE: 0,
  MAIL: 1,
} as const;
