import React, { FC } from "react";
import get from "lodash/get";
import { Navigate } from "react-router-dom";
import { ROUTES_PATHS } from "../helpers/routPaths";

import authenticationService from "../services/auth";

interface IAuthenticationRoute {
  component: FC;
}

const AuthenticationRoute: FC<IAuthenticationRoute> = ({
  component: Component,
}) => {
  const authData = authenticationService.getAuthData();
  const role = get(authData, "role", "");

  if (role) {
    return <Navigate to={ROUTES_PATHS.HOME} />;
  }

  return <Component />;
};

export default AuthenticationRoute;
