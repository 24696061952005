import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LOADING_STATES } from "helpers/constants";
import {
  EmptyTransactionAgentHistory,
  IAgentTransactionDetails,
  IClientTransactionDetails,
  IProgressStep,
  ITransactionAgentHistory,
  ITransactionItem,
  LoadingType,
  TransactionDetails,
} from "types";

interface TransactionState {
  client: {
    transactions: ITransactionItem[];
    transactionsLoading: LoadingType;
    transactionLoading: LoadingType;
    transaction: IClientTransactionDetails;
  };
  agent: {
    address: string;
    transactions: IAgentTransactionDetails[];
    transactionsLoading: LoadingType;
    progressSteps: IProgressStep[];
    details: any[];
    detailsLoading: LoadingType;
    transactionHistory:
      | ITransactionAgentHistory[]
      | EmptyTransactionAgentHistory[];
    transactionHistoryTotal: string | number;
    transactionHistoryLoading: LoadingType;
    transactionsDetails: TransactionDetails;
    transactionsDetailsLoading: LoadingType;
  };
}

const initialState: TransactionState = {
  client: {
    transactions: [],
    transactionsLoading: LOADING_STATES.IDLE,
    transaction: {
      address: {} as any,
      progressSteps: [],
      milestones: [],
      lastCompletedTaskDescription: "",
      userType: "",
      photoUrl: "",
      currentMilestoneDescription: {
        description: "",
        descriptionTitle: "",
      },
    },
    transactionLoading: LOADING_STATES.IDLE,
  },
  agent: {
    address: "",
    transactions: [],
    transactionsLoading: LOADING_STATES.IDLE,
    progressSteps: [],
    details: [],
    detailsLoading: LOADING_STATES.IDLE,
    transactionHistory: [],
    transactionHistoryTotal: 0,
    transactionHistoryLoading: LOADING_STATES.IDLE,
    // TODO Change type as TransactionDetails
    transactionsDetails: {} as TransactionDetails,
    transactionsDetailsLoading: LOADING_STATES.IDLE,
  },
};
// PayloadAction
export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setClientTransactions: (
      state,
      action: PayloadAction<ITransactionItem[]>
    ) => {
      if (state.client.transactionsLoading === LOADING_STATES.PENDING) {
        state.client.transactionsLoading = LOADING_STATES.IDLE;
        state.client.transactions = action.payload;
      }
    },
    setClientTransactionsLoading: (state) => {
      if (state.client.transactionsLoading === LOADING_STATES.IDLE) {
        state.client.transactionsLoading = LOADING_STATES.PENDING;
      }
    },
    setClientTransaction: (
      state,
      action: PayloadAction<IClientTransactionDetails>
    ) => {
      if (state.client.transactionLoading === LOADING_STATES.PENDING) {
        state.client.transactionLoading = LOADING_STATES.IDLE;
        state.client.transaction = action.payload;
      }
    },
    resetClientTransaction: (state) => {
      state.client.transaction = initialState.client.transaction;
    },
    setClientTransactionLoading: (state) => {
      if (state.client.transactionLoading === LOADING_STATES.IDLE) {
        state.client.transactionLoading = LOADING_STATES.PENDING;
      }
    },

    setAgentTransactions: (
      state,
      action: PayloadAction<IAgentTransactionDetails[]>
    ) => {
      if (state.agent.transactionsLoading === LOADING_STATES.PENDING) {
        state.agent.transactionsLoading = LOADING_STATES.IDLE;
        state.agent.transactions = action.payload;
      }
    },
    setAgentTransactionLoading: (state) => {
      if (state.agent.transactionsLoading === LOADING_STATES.IDLE) {
        state.agent.transactionsLoading = LOADING_STATES.PENDING;
      }
    },
    setAgentTransactionHistory: (
      state,
      action: PayloadAction<{
        data: ITransactionAgentHistory[] | EmptyTransactionAgentHistory[];
        total: string | number;
      }>
    ) => {
      if (state.agent.transactionHistoryLoading === LOADING_STATES.PENDING) {
        state.agent.transactionHistoryLoading = LOADING_STATES.IDLE;
        state.agent.transactionHistory = action.payload.data;
        state.agent.transactionHistoryTotal = action.payload.total;
      }
    },
    setAgentTransactionHistoryLoading: (state) => {
      if (state.agent.transactionHistoryLoading === LOADING_STATES.IDLE) {
        state.agent.transactionHistoryLoading = LOADING_STATES.PENDING;
      }
    },
    setAgentTransactionDetails: (
      state,
      action: PayloadAction<TransactionDetails>
    ) => {
      if (state.agent.transactionsDetailsLoading === LOADING_STATES.PENDING) {
        state.agent.transactionsDetailsLoading = LOADING_STATES.IDLE;
        if (action.payload) {
          state.agent.transactionsDetails = action.payload;
        }
      }
    },
    resetAgentTransactionDetails: (state) => {
      state.agent.transactionsDetails = initialState.agent.transactionsDetails;
    },
    setAgentTransactionDetailsLoading: (state) => {
      if (state.agent.transactionsDetailsLoading === LOADING_STATES.IDLE) {
        state.agent.transactionsDetailsLoading = LOADING_STATES.PENDING;
      }
    },
    resetTransactions: (state) => {
      state = initialState;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setClientTransactions,
  setClientTransactionsLoading,
  setClientTransaction,
  resetClientTransaction,
  setClientTransactionLoading,
  setAgentTransactions,
  setAgentTransactionLoading,
  setAgentTransactionHistory,
  setAgentTransactionHistoryLoading,
  setAgentTransactionDetails,
  setAgentTransactionDetailsLoading,
  resetAgentTransactionDetails,
  resetTransactions,
} = transactionSlice.actions;

export default transactionSlice.reducer;
