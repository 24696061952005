import React, { FC } from "react";
import get from "lodash/get";
import { Navigate } from "react-router-dom";
import { ROUTES_PATHS } from "../helpers/routPaths";

import authenticationService from "../services/auth";

interface PrivateRouteProps {
  component: FC;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component }) => {
  const authData = authenticationService.getAuthData();
  const role = get(authData, "role", "");
  const token = get(authData, "token", "");

  if (!authData) {
    return <Navigate to={{ pathname: ROUTES_PATHS.LOGIN }} />;
  }

  if (authData.setPassword) {
    return <Navigate to={ROUTES_PATHS.SET_PASSWORD} />;
  }

  if (!token || !role || role.length === 0) {
    return <Navigate to={{ pathname: ROUTES_PATHS.UNAUTHORIZED }} />;
  }

  return <Component />;
};

export default PrivateRoute;
