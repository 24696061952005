export const ROUTES_PATHS = {
  HOME: "/dashboard",
  CLIENT: "/dashboard/client",
  AGENT: "/dashboard/agent",
  TRANSACTION: "/dashboard/client/:transactionId",
  LOGIN: "/login",
  UNAUTHORIZED: "/unauthorized",
  FORGOT_PASSWORD: "/forgotPassword",
  SET_PASSWORD: "/setPassword",
  CREATE_PASSWORD: "/createPassword",
  ISSUE: "/issue",
  AUTHENTICATE: "/authenticate",
  TERMS_CONDITIONS: "/terms",
  PRIVACY_POLICY: "/privacyPolicy",
} as const;
