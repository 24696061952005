import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LOADING_STATES } from "helpers/constants";
import { IDocument, LoadingType } from "types";

interface DocumentState {
  documents: IDocument[];
  loading: LoadingType;
}

const initialState: DocumentState = {
  documents: [],
  loading: LOADING_STATES.IDLE,
};

export const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    setLoading: (state) => {
      if (state.loading === LOADING_STATES.IDLE) {
        state.loading = LOADING_STATES.PENDING;
      }
    },
    setDocument: (state, action: PayloadAction<IDocument[]>) => {
      if (state.loading === LOADING_STATES.PENDING) {
        state.loading = LOADING_STATES.IDLE;
        state.documents = action.payload;
      }
    },
    resetDocument: (state) => {
      state = initialState;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setDocument, resetDocument } = documentSlice.actions;

export default documentSlice.reducer;
