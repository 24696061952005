import React, { Suspense, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ROUTES_PATHS } from "./helpers/routPaths";

import Spinner from "./components/shared/Spinner";
import AuthenticationRoute from "./routes/AuthenticationRoute";
import PrivateRoute from "./routes/PrivateRoute";

const Authenticate = lazy(() => import("./pages/AuthenticatePage"));
const ForgotPasswordPage = lazy(() => import("./pages/ForgotPasswordPage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const SetPasswordPage = lazy(() => import("./pages/SetPasswordPage"));
const CreatePasswordPage = lazy(() => import("./pages/CreatePasswordPage"));
const UnauthorizedPage = lazy(() => import("./pages/UnauthorizedPage"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Agent = lazy(() => import("./pages/Agent"));
const ClientHomePage = lazy(() => import("./pages/ClientHomePage"));
const TransactionPage = lazy(() => import("./pages/TransactionPage"));
const HomePage = lazy(() => import("./pages/HomePage"));

const App = () => {
  const {
    HOME,
    CLIENT,
    AGENT,
    TRANSACTION,
    LOGIN,
    UNAUTHORIZED,
    FORGOT_PASSWORD,
    SET_PASSWORD,
    CREATE_PASSWORD,
    AUTHENTICATE,
    TERMS_CONDITIONS,
    PRIVACY_POLICY,
  } = ROUTES_PATHS;

  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route
            path={LOGIN}
            element={<AuthenticationRoute component={LoginPage} />}
          />
          <Route
            path={UNAUTHORIZED}
            element={<AuthenticationRoute component={UnauthorizedPage} />}
          />
          <Route
            path={FORGOT_PASSWORD}
            element={<AuthenticationRoute component={ForgotPasswordPage} />}
          />
          <Route
            path={SET_PASSWORD}
            element={<AuthenticationRoute component={SetPasswordPage} />}
          />
          <Route
            path={CREATE_PASSWORD}
            element={<AuthenticationRoute component={CreatePasswordPage} />}
          />
          <Route path={AUTHENTICATE} element={<Authenticate />} />
          <Route path={TERMS_CONDITIONS} element={<TermsAndConditions />} />
          <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={HOME} element={<HomePage />} />
          <Route path={AGENT} element={<PrivateRoute component={Agent} />} />
          <Route
            path={CLIENT}
            element={<PrivateRoute component={ClientHomePage} />}
          />
          <Route
            path={TRANSACTION}
            element={<PrivateRoute component={TransactionPage} />}
          />
          <Route path="/*" element={<Navigate to={HOME} />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default App;
