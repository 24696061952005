import { createSlice } from "@reduxjs/toolkit";
import { IBranding, IUserInfo, LoadingType } from "types";

export interface StoreUserinfo extends IUserInfo {
  avatarLoading?: LoadingType;
}

interface UserState {
  loadingLogin: LoadingType;
  loadingUserInfo: LoadingType;
  loadingBranding: LoadingType;
  userInfo?: StoreUserinfo;
  // TO DO: change to IBranding
  branding: Record<string, string>;
  // TO DO: change to something
  validationErrors: Record<string, string>;
  passwordStatus: boolean;
  emailSentStatus: boolean;
}

const initialState: UserState = {
  loadingLogin: "idle",
  loadingUserInfo: "idle",
  loadingBranding: "idle",
  userInfo: undefined,
  branding: {},
  validationErrors: {},
  passwordStatus: false,
  emailSentStatus: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoadingLogin: (state, action) => {
      state.loadingLogin = action.payload;
    },
    setLoadingUserInfo: (state) => {
      if (
        state.loadingUserInfo === "idle" ||
        state.loadingUserInfo === "succeeded"
      ) {
        state.loadingUserInfo = "pending";
      }
    },
    setLoadingBranding: (state) => {
      if (state.loadingBranding === "idle") {
        state.loadingBranding = "pending";
      }
    },
    setUserInfo: (state, action) => {
      if (state.loadingUserInfo === "pending") {
        state.loadingUserInfo = "succeeded";
        state.userInfo = action.payload;
      }
    },
    setUserAvatar: (state, action) => {
      if (state.userInfo) {
        state.userInfo.avatarUrl = action.payload;
      }
    },
    setUpdateUserAvatarLoading: (state, action) => {
      if (state.userInfo) {
        state.userInfo.avatarLoading = action.payload;
      }
    },
    setBranding: (state, action) => {
      if (state.loadingBranding === "pending") {
        state.loadingBranding = "succeeded";
        state.branding = action.payload;
      }
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = action.payload;
    },
    setPasswordStatus: (state) => {
      state.passwordStatus = true;
    },
    setEmailSentStatus: (state, action) => {
      state.emailSentStatus = action.payload;
    },
    resetUser: (state) => {
      state = initialState;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoadingLogin,
  setLoadingUserInfo,
  setLoadingBranding,
  setUserInfo,
  setUserAvatar,
  setUpdateUserAvatarLoading,
  setBranding,
  setValidationErrors,
  setPasswordStatus,
  setEmailSentStatus,
  resetUser,
} = userSlice.actions;

export default userSlice.reducer;
