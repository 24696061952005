import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LOADING_STATES } from "helpers/constants";
import { ITeamMember, LoadingType } from "types";

interface TeamMemberState {
  teamMembers: ITeamMember[];
  loading: LoadingType;
}

const initialState: TeamMemberState = {
  teamMembers: [],
  loading: LOADING_STATES.IDLE,
};

export const teamMemberSlice = createSlice({
  name: "teamMember",
  initialState,
  reducers: {
    setLoading: (state) => {
      if (state.loading === LOADING_STATES.IDLE) {
        state.loading = LOADING_STATES.PENDING;
      }
    },
    setTeamMember: (state, action: PayloadAction<ITeamMember[]>) => {
      if (state.loading === LOADING_STATES.PENDING) {
        state.loading = LOADING_STATES.IDLE;
        state.teamMembers = action.payload;
      }
    },
    updateTeamMember: (state, action: PayloadAction<ITeamMember[]>) => {
      state.teamMembers = action.payload;
    },
    resetTeamMember: (state) => {
      state = initialState;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setTeamMember, updateTeamMember, resetTeamMember } =
  teamMemberSlice.actions;

export default teamMemberSlice.reducer;
