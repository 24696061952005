import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LOADING_STATES } from "helpers/constants";
import { IMilestone, LoadingType } from "types";

interface MilestoneState {
  milestones: IMilestone[];
  loading: LoadingType;
  filters: {
    hideCompleted: boolean;
    dates:
      | {
          from?: string;
          to?: string;
        }
      | any;
  };
}

const initialState: MilestoneState = {
  milestones: [],
  loading: LOADING_STATES.IDLE,
  filters: {
    hideCompleted: false,
    dates: {},
  },
};

export const milestoneSlice = createSlice({
  name: "milestone",
  initialState,
  reducers: {
    setLoading: (state) => {
      if (state.loading === LOADING_STATES.IDLE) {
        state.loading = LOADING_STATES.PENDING;
      }
    },
    setMilestone: (state, action: PayloadAction<IMilestone[]>) => {
      if (state.loading === LOADING_STATES.PENDING) {
        state.loading = LOADING_STATES.IDLE;
        state.milestones = action.payload || [];
      }
    },
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetMilestone: (state) => {
      state = initialState;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFilters, setMilestone, setLoading, resetMilestone } =
  milestoneSlice.actions;

export default milestoneSlice.reducer;
