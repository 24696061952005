import {
  COMMUNICATOR_API_KEY,
  COMMUNICATOR_CURRENT_USER_KEY,
} from "../../api/constants";

const getAuthData = () => {
  const data =
    sessionStorage.getItem(COMMUNICATOR_API_KEY) ||
    localStorage.getItem(COMMUNICATOR_API_KEY);
  if (!data) {
    return null;
  }

  return JSON.parse(data);
};

const getCurrentUserType = () => {
  const data = sessionStorage.getItem(COMMUNICATOR_CURRENT_USER_KEY);
  if (!data) {
    return {};
  }

  return JSON.parse(data);
};

const clearCurrentUserTypeData = () => {
  sessionStorage.removeItem(COMMUNICATOR_CURRENT_USER_KEY);
};

const clearAuthData = () => {
  sessionStorage.removeItem(COMMUNICATOR_API_KEY);
  localStorage.removeItem(COMMUNICATOR_API_KEY);
  clearCurrentUserTypeData();
};

const setAuthData = ({ rememberMeStatus, ...authData }: any) => {
  const existingAuthData = getAuthData();

  if (existingAuthData) {
    clearAuthData();
  }
  const data = JSON.stringify(authData);
  const storage = rememberMeStatus ? localStorage : sessionStorage;
  storage.setItem(COMMUNICATOR_API_KEY, data);
};

const setCurrentUserType = (currentRoleData: any) => {
  if (!currentRoleData) {
    return;
  }

  if (getCurrentUserType()) {
    clearCurrentUserTypeData();
  }

  const data = JSON.stringify(currentRoleData);
  sessionStorage.setItem(COMMUNICATOR_CURRENT_USER_KEY, data);
};

const authenticationService = {
  getAuthData,
  setAuthData,
  clearAuthData,
  getCurrentUserType,
  setCurrentUserType,
  clearCurrentUserTypeData,
};

export default authenticationService;
